<script lang="ts" setup>
import { TransitionGroup } from "vue";

const system = useSystemStore();
</script>

<template>
  <TransitionGroup
    tag="ul"
    name="messages"
    class="fixed top-24 right-8 pointer-events-none flex flex-col items-end z-[100]"
  >
    <li
      v-for="msg in system.messages"
      :key="msg.message"
      :class="`pointer-events-auto py-2 px-4 ${
        msg.type === 'error' ? 'bg-red-300' : 'bg-green-200'
      } rounded-md mb-4 shadow-md text-sm flex items-center`"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6 mr-3"
        v-if="msg.type === 'error'"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-4 mr-3"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m4.5 12.75 6 6 9-13.5"
        />
      </svg>
      <span class="max-w-md">{{ msg.message }}</span>
      <button type="button" @click="system.remove(msg)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-4 ml-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
        <span class="sr-only">Close</span>
      </button>
    </li>
  </TransitionGroup>
</template>

<style>
.messages-enter-active,
.messages-leave-active {
  transition: all 0.25s ease;
}
.messages-enter-from,
.messages-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
</style>
