<script setup lang="ts">
const system = useSystemStore();
const { formModalOpen } = storeToRefs(system);
const modalDialog = ref<HTMLElement | null>(null);

onMounted(() => {
  modalDialog.value?.addEventListener("close", () => {
    formModalOpen.value = false;
  });
  modalDialog.value?.addEventListener("click", function (event) {
    const rect = modalDialog.value?.getBoundingClientRect();
    const isInDialog =
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width;
    if (!isInDialog) {
      modalDialog.value?.close();
    }
  });
});

watch(formModalOpen, (isOpen) => {
  if (isOpen) {
    modalDialog.value?.showModal();
  } else {
    modalDialog.value?.close();
  }
});
</script>

<template>
  <transition name="modal">
    <dialog
      ref="modalDialog"
      v-show="formModalOpen"
      :class="`content ${
        formModalOpen ? 'flex' : '!hidden'
      } backdrop:bg-slate-100/60 backdrop:backdrop-blur-[8px] max-w-[calc(100%-1em)] w-full sm:max-w-screen-sm flex-col items-center justify-center bg-white px-5 py-4 md:p-10 md:pt-8 border-2 border-slate-200 shadow-2xl shadow-pink-300/40 rounded-xl`"
    >
      <div class="pl-1.5 mr-auto">
        <Logo class="mr-auto mb-1" />
        <p class="text-sm mr-auto text-slate-600 mb-2">
          Describe the form you want to make today
        </p>
      </div>
      <!-- <h2
        class="w-full text-2xl sm:text-3xl xl:text-4xl font-bold text-balance text-blue-900 dark:text-blue-50 max-w-full overflow-visible"
      >
        Create any form.<br />
        <span class="text-pink-600">Instantly</span>. For Free.
      </h2> -->
      <!-- <p
        class="w-full text-balance max-w-screen-md text-slate-500 text-base lg:text-lg leading-tight mb-6 mt-2"
      >
        Try it now — it's fast! 👇
      </p> -->

      <NewFormInput
        v-if="formModalOpen"
        input-id="modal"
        :shadow="false"
        :focus-on-mount="true"
        :use-dynamic-placeholder="true"
      />
    </dialog>
  </transition>
</template>

<style scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease-in-out;
  display: flex !important;
  transform: scale(1);
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transition-duration: 0;
  transform: scale(0);
}
</style>
