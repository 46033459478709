<script setup lang="ts">
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const system = useSystemStore();
const { formModalOpen } = storeToRefs(system);

function openCreateFormModal() {
  formModalOpen.value = true;
}

const userStore = useUserStore();
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger
      class="data-[state=open]:ring-2 hover:ring-2 ring-pink-500 rounded-full"
      id="user-navigation-trigger"
      ><slot
    /></DropdownMenuTrigger>
    <DropdownMenuPortal>
      <DropdownMenuContent class="z-[90] min-w-[180px]">
        <DropdownMenuItem class="hover:!bg-transparent !cursor-default">
          <Button
            size="sm"
            class="flex items-center w-full !bg-pink-600 hover:!bg-pink-700"
            @click="openCreateFormModal"
          >
            <Icon name="mdi:plus" class="w-5 h-5 mr-1 text-slate-100" />
            <span class="font-slate-700">New Form</span>
          </Button>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <NuxtLink to="/dashboard">
          <DropdownMenuItem>
            <Avatar class="!w-6 !h-6 mr-2" />
            <span class="font-slate-700">My Forms</span>
          </DropdownMenuItem>
        </NuxtLink>
        <NuxtLink
          v-for="team in userStore.teams"
          :to="`/dashboard/${team.slug}`"
        >
          <DropdownMenuItem>
            <Avatar :for="team" class="!w-6 !h-6 mr-2" />
            <span class="font-slate-700">{{ team.name }}</span>
          </DropdownMenuItem>
        </NuxtLink>
        <NuxtLink to="/dashboard/create-team">
          <DropdownMenuItem class="group">
            <div class="flex items-center">
              <span
                class="flex !w-6 !h-6 mr-2 border border-slate-400 group-hover:border-pink-600 rounded-full bg-white"
              >
                <Icon
                  name="mdi:plus"
                  class="w-4 h-4 m-auto text-slate-500 group-hover:text-pink-600"
                />
              </span>
              <span class="font-slate-700">Create Team</span>
            </div>
          </DropdownMenuItem>
        </NuxtLink>
        <DropdownMenuSeparator />
        <NuxtLink to="/dashboard/account">
          <DropdownMenuItem class="group flex flex-1">
            <div class="flex flex-1 w-full items-center">
              <span>Account Settings</span>
              <Icon
                name="ic:outline-settings"
                class="w-5 h-5 ml-auto text-slate-600 group-hover:text-pink-600"
              />
            </div>
          </DropdownMenuItem>
        </NuxtLink>
        <NuxtLink to="/affiliate">
          <DropdownMenuItem class="group flex flex-1">
            <div class="flex flex-1 w-full items-center">
              <span>Affiliate Program</span>
              <Icon
                name="mdi:gift-outline"
                class="w-5 h-5 ml-auto text-slate-600 group-hover:text-pink-600"
              />
            </div>
          </DropdownMenuItem>
        </NuxtLink>
        <NuxtLink to="/logout">
          <DropdownMenuItem class="group flex flex-1">
            <div class="flex flex-1 w-full items-center">
              <span>Logout</span>
              <Icon
                name="material-symbols:logout"
                class="w-5 h-5 ml-auto text-slate-600 group-hover:text-pink-600"
              />
            </div>
          </DropdownMenuItem>
        </NuxtLink>
        <DropdownMenuSeparator />
        <DropdownMenuItem class="hover:!bg-transparent !cursor-default">
          <NuxtLink to="/pricing" class="group flex flex-1">
            <Button
              size="sm"
              class="text-green-600 flex flex-1 items-center !bg-black"
            >
              <Icon
                name="mdi:lightning-bolt"
                class="w-5 h-5 mr-1 text-yellow-200 group-hover:text-pink-400"
              />
              Upgrade Now!
            </Button>
          </NuxtLink>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenu>
</template>
